import { render, staticRenderFns } from "./device-event-detail-drawer.vue?vue&type=template&id=f716640a&scoped=true"
import script from "./device-event-detail-drawer.vue?vue&type=script&lang=ts"
export * from "./device-event-detail-drawer.vue?vue&type=script&lang=ts"
import style0 from "./device-event-detail-drawer.vue?vue&type=style&index=0&id=f716640a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f716640a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins_home/workspace/test-物联业务中台(test-nimbus_web_bi)/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f716640a')) {
      api.createRecord('f716640a', component.options)
    } else {
      api.reload('f716640a', component.options)
    }
    module.hot.accept("./device-event-detail-drawer.vue?vue&type=template&id=f716640a&scoped=true", function () {
      api.rerender('f716640a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "nimbus-web-common/src/pages3/dashboard/device/event/device-event-detail-drawer.vue"
export default component.exports